<template>
  <div>
    <div v-if="showLoading" class="loading">
      <bounce-loader />
    </div>
    <div v-if="showLog">
      <LogView />
    </div>
    <template v-else>
      <Editor
        v-bind:style="{ display: (currentFileType == 'js' || currentFileType == 'lua' || currentFileType == 'txt') ? 'block' : 'none' }"
      />
      <div v-if="currentFileType == 'img'">
        <ImageView />
      </div>
    </template>
  </div>
</template>

<script>
import Editor from './Editor.vue'
import LogView from './LogView.vue'
import ImageView from './ImageView.vue'
import { mapGetters } from 'vuex'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
const common = require('../common')

export default {
  name: 'MainField',
  components: {
    Editor,
    LogView,
    ImageView,
    BounceLoader
  },
  data () {
    return {
      currentFileType: null
    }
  },
  computed: {
    ...mapGetters({
      currentFile: 'getCurrentFile',
      showLog: 'getShowLog',
      showLoading: 'getShowLoading'
    })
  },
  watch: {
    currentFile: function (val) {
      this.currentFileType = common.getFileType(val.filePath)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.loading {
  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 500px;
}
</style>
