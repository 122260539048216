export function getFilename (filePath) {
  const elements = filePath.split('/')
  return elements[elements.length - 1]
}
export function getCurrentPath () {
  let search = window.location.search
  if (search.length > 2) {
    search = search.substring(1, search.length)
    const keyValueStrList = search.split('&')
    for (let i = 0; i < keyValueStrList.length; i++) {
      const keyValue = keyValueStrList[i].split('=')
      if (keyValue[0] === 'path') {
        return decodeURIComponent(keyValue[1])
      }
    }
  }
  return '/'
}
export function isFolder (iconName) {
  return iconName.indexOf('folder') === 0
}
export function isImage (iconName) {
  return iconName.indexOf('bmp') === 0 ||
        iconName.indexOf('png') === 0 ||
        iconName.indexOf('jpg') === 0 ||
        iconName.indexOf('jpeg') === 0
}
export function isScript (filePath) {
  const p = filePath.toLowerCase()
  return p.endsWith('.js') ||
        p.endsWith('.lua') ||
        p.endsWith('.at') ||
        p.endsWith('.ate')
}

export function isLuaPackage (filePath) {
  const p = filePath.toLowerCase()
  return p.endsWith('.at')
}

export function getFileType (filePath) {
  const p = filePath.toLowerCase()
  if (p.endsWith('.js')) return 'js'
  if (p.endsWith('.lua')) return 'lua'
  if (p.endsWith('.txt')) return 'txt'
  if (p.endsWith('.bmp') ||
        p.endsWith('.png') ||
        p.endsWith('.jpg') ||
        p.endsWith('.jpeg')) { return 'img' }
  return 'unkown'
}

/**
 * Compares two version strings.
 * @param {string} v1 - The first version string.
 * @param {string} v2 - The second version string.
 * @returns {number} 1 if v1 > v2, -1 if v1 < v2, 0 if v1 === v2.
 *
 * Note: Version strings can be in the following formats:
 * - 'x.y.z' (e.g., '8.2.13')
 * - 'x.y.z-w' (e.g., '8.2.13-54')
 * - 'x.y.z-w+build' (e.g., '8.2.13-54+debug')
 */
export function compareVersions (v1, v2) {
  if (typeof v1 !== 'string' || typeof v2 !== 'string') {
    throw new Error('Both arguments must be strings')
  }

  // Remove build metadata if present
  v1 = v1.split('+')[0]
  v2 = v2.split('+')[0]

  // Split version strings into parts
  const parts1 = v1.split(/[-.]/).map(part => parseInt(part, 10) || 0)
  const parts2 = v2.split(/[-.]/).map(part => parseInt(part, 10) || 0)

  const maxLength = Math.max(parts1.length, parts2.length)

  for (let i = 0; i < maxLength; i++) {
    const part1 = parts1[i] || 0
    const part2 = parts2[i] || 0

    if (part1 > part2) return 1
    if (part1 < part2) return -1
  }

  return 0
}
