<template>
  <nav class="navbar navbar-dark bg-dark">
    <div class="container-fluid">
      <button class="btn-collapse" v-on:click="toggleLeft()">
        <svg
          class="bi bi-list"
          width="2.2em"
          height="2.2em"
          viewBox="0 0 16 16"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <a class="navbar-brand" href="../">AutoTouch Dashboard</a>
      <div v-if="deviceInfo">
        <span v-if="deviceInfo.deviceName" class="navbar-text">Device Name: <span class="font-bold">{{ deviceInfo.deviceName }}</span></span>
        <span v-if="deviceInfo.deviceModel" class="navbar-text">Device Model: <span class="font-bold">{{ deviceInfo.deviceModel }}</span></span>
        <span v-if="deviceInfo.osVersion" class="navbar-text ml-2">OS: <span class="font-bold">iOS {{ deviceInfo.osVersion }}</span></span>
        <span v-if="deviceInfo.ip" class="navbar-text ml-2">Local IP: <span class="font-bold">{{ deviceInfo.ip }}</span></span>
        <span v-if="deviceInfo.sn" class="navbar-text ml-2">SN: <span class="font-bold">{{ deviceInfo.sn }}</span></span>
        <span v-if="deviceInfo.version" class="navbar-text ml-2">AutoTouch Version: <span class="font-bold">{{ deviceInfo.version }}</span></span>
      </div>
      <div class="navbar-nav ml-auto">
        <button class="btn btn-secondary btn-sm" v-on:click="switchLog(!showLog)">Show Log</button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  data () {
    return {
      showLeft: true,
      showLog: false
    }
  },
  computed: {
    ...mapGetters(['getDeviceInfo']),
    deviceInfo () {
      return this.getDeviceInfo
    }
  },
  methods: {
    switchLog (show) {
      this.showLog = show
      this.$store.commit('setShowLog', show)
    },
    toggleLeft () {
      this.showLeft = !this.showLeft
      this.$store.commit('setShowLeft', this.showLeft)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navbar {
  padding: 0;
  box-shadow: 0 0 1px #aaaaaa;
  z-index: 1000;

  .container-fluid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .btn-collapse {
    padding: 0;
    margin: 0;
    background-color: rgba($color: #000000, $alpha: 0);
    border: 0;
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    padding-top: 3px;

    &:focus {
      outline: none;
    }
  }

  .navbar-brand {
    font-size: 1rem;
    font-weight: 600;
  }

  .navbar-text {
    color: #b0b0b0;
    font-size: 0.7rem;
    font-weight: normal;
    margin-left: 1rem;
  }
}
</style>
