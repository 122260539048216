<template>
  <div class="footer">
    <div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  >div {
    margin-top: 10px;
    // background-color: #343a40;
    width: 100%;
    height: 25px;
  }
}
</style>
