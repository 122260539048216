<template>
  <div class="log-view">
    <div class="toolbar d-flex flex-row justify-content-between">
      <div></div>
      <button class="btn btn-secondary btn-sm" v-on:click="clearLog()">Clear Log</button>
    </div>
    <div class="log">
      <pre>{{log}}</pre>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LogView',
  components: {},
  data () {
    return {
      log: null,
      refreshIntervalId: null
    }
  },
  mounted () {
    this.getLog(true)
    this.refreshIntervalId = setInterval(() => {
      this.getLog()
    }, 2000)
  },
  destroyed () {
    clearInterval(this.refreshIntervalId)
  },
  methods: {
    getLog (showLoading = false) {
      const self = this

      if (showLoading) {
        self.$store.commit('setShowLoading', true)
      }

      const url = '/log'
      self.$http
        .get(url)
        .then(function (response) {
          const respObj = response.data
          if (respObj.status === 'success') {
            self.log = respObj.content
          } else {
            console.error('Failed to get log:', respObj.info)
          }
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
        .finally(function () {
          if (showLoading) {
            self.$store.commit('setShowLoading', false)
          }
        })
    },
    clearLog () {
      const self = this
      const url = '/log/clear'
      self.$http
        .get(url)
        .then(function (response) {
          const respObj = response.data
          if (respObj.status === 'success') {
            self.log = null
            self.$notify({
              group: 'main',
              type: 'success',
              text: 'Cleared!'
            })
          } else {
            self.$notify({
              group: 'main',
              type: 'error',
              text: respObj.info
            })
          }
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.log-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 500px;

  .log {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 500px;

    border: 1px solid #eeeeee;
    padding: 10px;

    pre {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
