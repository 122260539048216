<template>
  <div id="app">
    <template v-if="host">
      <Navbar class="top" />
      <div class="main" v-bind:class="{hideLeft: !showLeft, showLeft: showLeft}">
        <div class="left">
          <router-view></router-view>
        </div>
        <MainField class="right" />
      </div>
      <AppFooter class="bottom" />
      <notifications group="main" position="bottom right" />
    </template>
    <div v-else class="container-fluid" style="margin-top: 100px;">
      <div>
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <div class="brand">
                <img src="/logo.png" />
                <h1 class="mt-3 mb-5">AutoTouch Dashboard</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2 d-flex justify-content-center">
            <ol class="text-secondary" style="max-width: 600px;">
                <li>Turn on Web Server at AutoTouch>Settings, then input the address here.</li>
                <li>Mind the server port, and be clear it's http:// or https://.</li>
            </ol>
          </div>
          <div class="col-12">
            <form class="d-flex justify-content-center align-items-baseline">
              <h6 class="text-nowrap">Device Server Address:</h6>
              <input
                type="text"
                v-model="hostInput"
                class="form-control mx-3"
                style="width: 300px;"
                placeholder="http://192.168.1.123:8080"
              />
              <button class="btn btn-success" @click.stop.prevent="connect">Connect</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import FileList from '../components/FileList.vue'
import MainField from '../components/MainField.vue'
import AppFooter from '../components/Footer.vue'
import VueRouter from 'vue-router'
import { mapGetters, mapMutations } from 'vuex'

const routes = [{ path: '**', component: FileList }]

const router = new VueRouter({
  routes
})

export default {
  name: 'app',
  router,
  components: {
    Navbar,
    MainField,
    AppFooter
  },
  data () {
    return {
      host: null,
      hostInput: null
    }
  },
  computed: {
    ...mapGetters({
      showLeft: 'getShowLeft'
    })
  },
  created () {
    const host = this.$route.query.host
    if (host) {
      this.updateHost(host)
    }
  },
  methods: {
    ...mapMutations(['setDeviceInfo']),
    updateHost (host) {
      if (host) {
        this.host = host

        if (host.startsWith('https://') || host.startsWith('http://')) {
          this.$http.defaults.baseURL = host
        } else {
          this.$http.defaults.baseURL = 'http://' + host
        }

        this.$router.replace({ query: { host } }).catch(() => {})

        // Fetch version from API
        this.fetchVersion()
      }
    },
    connect () {
      this.updateHost(this.hostInput)
    },
    async fetchVersion () {
      try {
        const response = await this.$http.get('/deviceInfo')
        const deviceInfo = response.data
        this.setDeviceInfo(deviceInfo)
      } catch (error) {
        console.error('Failed to fetch version:', error)
        // Optionally, you can show an error notification to the user
      }
    }
  }
}
</script>
<style lang="scss" scoped>
$navbarHeight: 40px;
$footerHeight: 15px;
$leftWidth: 500px;

@keyframes show-left-left-animation {
  from {
    left: -$leftWidth;
  }
  to {
    left: 0;
  }
}

@keyframes show-left-right-animation {
  from {
    left: 0;
  }
  to {
    left: $leftWidth + 10;
  }
}

@keyframes hide-left-left-animation {
  to {
    left: -$leftWidth;
  }
  from {
    left: 0;
  }
}

@keyframes hide-left-right-animation {
  to {
    left: 0;
  }
  from {
    left: $leftWidth + 10;
  }
}

#app {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 1010px;
}

.top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: $navbarHeight;
}

.main {
  position: absolute;
  top: $navbarHeight;
  left: 0;
  right: 0;
  bottom: $footerHeight;

  .left {
    position: absolute;
    top: 0;
    bottom: 0;
    width: $leftWidth;
  }

  .right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.hideLeft {
  .left {
    left: -$leftWidth;
    animation-name: hide-left-left-animation;
    animation-duration: 0.5s;
  }
  .right {
    left: 0;
    animation-name: hide-left-right-animation;
    animation-duration: 0.5s;
  }
}

.showLeft {
  .left {
    left: 0;
    animation-name: show-left-left-animation;
    animation-duration: 0.5s;
  }
  .right {
    left: $leftWidth + 10px;
    animation-name: show-left-right-animation;
    animation-duration: 0.5s;
  }
}

.bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: $footerHeight;
}
</style>
