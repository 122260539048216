<template>
  <div class="img-view">
    <div class="toolbar">
      <!-- <div v-if="currentFile.filePath" v-text="'File: ' + currentFile.filePath"></div> -->
    </div>
    <div class="img-container">
      <img v-bind:src="`${currentFile.content}`" alt>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ImageView',
  computed: {
    ...mapGetters({
      currentFile: 'getCurrentFile'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.img-view {
  .img-container {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 500px;

    text-align: center;

    border: 1px solid #eeeeee;
    padding: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
