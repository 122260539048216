<template>
  <ol class="breadcrumb" v-html="breadcrumbHTML"></ol>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'breadcrumb',
  data () {
    return {
      breadcrumbHTML: null
    }
  },
  created () {
    this.updateBreadcrumbHTML()
  },
  watch: {
    currentDir: function () {
      this.updateBreadcrumbHTML()
    }
  },
  computed: {
    ...mapGetters({
      currentDir: 'getCurrentDir'
    })
  },
  methods: {
    updateBreadcrumbHTML () {
      const currentDir = this.$store.state.currentDir
      const pathElems = currentDir.split('/')
      let html = ''
      let path = ''
      for (let i = 0; i < pathElems.length; i++) {
        if (i === 0) {
          path = '/'
        } else if (i === 1) {
          path += pathElems[i]
        } else {
          path += '/' + pathElems[i]
        }

        if (i === 0) {
          html +=
            '<li class="breadcrumb-item"><a href="#' +
            path +
            '">Home</a></li>'
        } else if (i === pathElems.length - 1) {
          html +=
            '<li class="breadcrumb-item active">' + pathElems[i] + '</li>'
        } else {
          html +=
            '<li class="breadcrumb-item"><a href="#' +
            path +
            '">' +
            pathElems[i] +
            '</a></li>'
        }
      }
      this.breadcrumbHTML = html
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin-bottom: 0;
}
</style>
