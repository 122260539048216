<template>
  <div>
    <div class="toolbar">
      <div v-if="currentScript.filePath" v-text="'File: ' + currentScript.filePath"></div>
      <div v-else></div>
      <div class="d-flex flex-row justify-content-end align-items-center pr-3">
        <template v-if="code">
          <div class="m-1">Font Size</div>
          <b-form-spinbutton v-model="options.fontSize" inline size="sm" class="m-2"></b-form-spinbutton>

          <select v-model="options.theme" class="m-2 mr-5" style="height: 30px;">
            <option value="vs-dark">Dark</option>
            <option value="vs">Light</option>
          </select>

          <button class="btn btn-secondary btn-sm m-2" v-on:click="play()">
            Play
            <span class="span-btn-subtitle">(Ctrl+r,⌘+r)</span>
          </button>

          <button class="btn btn-secondary btn-sm m-2" v-on:click="saveFile()" v-if="!isSaving">
            Save
            <span class="span-btn-subtitle">Ctrl+s,⌘+s)</span>
          </button>
          <button class="btn btn-secondary btn-sm m-2" disabled v-else>
            Saving
            <span class="span-btn-subtitle">Ctrl+s,⌘+s)</span>
          </button>
        </template>
      </div>
    </div>

    <!-- https://forum.vuejs.org/t/vue-warn-invalid-prop-type-check-failed-for-prop-value-expected-string-with-value-null-got-null/88629 -->

    <div class="editor-field">
      <MonacoEditor
        v-if="code != null"
        class="editor"
        v-model="code"
        :language="language"
        :theme="options.theme"
        :options="options"
      />
    </div>
  </div>
</template>
<script>
import MonacoEditor from '@guolao/vue-monaco-editor'
import { mapGetters } from 'vuex'
const common = require('../common')

export default {
  name: 'Editor',
  components: {
    MonacoEditor
  },
  data () {
    return {
      fileType: null,
      isSaving: false,
      code: this.currentScript ? this.currentScript.content : null, // blank string is ok
      language: null,
      options: {
        fontSize: 13,
        theme: 'vs-dark'
      }
    }
  },
  created () {
    window.addEventListener('keydown', this.onHotKey, false)
  },
  beforeDestroy: function () {
    window.removeEventListener('keydown', this.onHotKey)
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'getDeviceInfo',
      currentScript: 'getCurrentFile'
    })
  },
  watch: {
    currentScript: function (val) {
      this.code = val.content
      this.fileType = common.getFileType(val.filePath)
      this.updateMode()
    }
  },
  methods: {
    saveFile () {
      const self = this

      self.isSaving = true

      const filePath = this.currentScript.filePath

      const url = '/file/update?path=' + encodeURIComponent(filePath)

      // Check AutoTouch version to determine the correct format for the POST request body
      // For versions >= 8.2.13, send the content directly
      // For older versions, use form URL encoded format
      // This version check also affects the Content-Type header
      let content
      let contentType
      if (this.deviceInfo && this.deviceInfo.version && common.compareVersions(this.deviceInfo.version, '8.2.13') >= 0) {
        content = this.code
        contentType = 'text/plain'
      } else {
        content = 'content=' + encodeURIComponent(this.code)
        contentType = 'application/x-www-form-urlencoded'
      }

      self.$http
        .post(url, content, {
          headers: { 'Content-Type': contentType }
        })
        .then(function (response) {
          const respObj = response.data
          if (respObj.status === 'success') {
            self.$store.commit('setCurrentFile', {
              filePath,
              content: self.code
            })
          } else {
            self.$notify({
              group: 'main',
              type: 'error',
              text: respObj.info
            })
          }
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
        .then(function () {
          self.isSaving = false
        })
    },
    onHotKey (event) {
      if (event.defaultPrevented) {
        return // Should do nothing if the default action has been cancelled
      }

      if (
        (event.which === 83 || event.keyCode === 83 || event.key === 's') &&
        (navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey)
      ) {
        this.saveFile()
        event.preventDefault()
      }

    //   if (
    //     (event.which === 82 || event.keyCode === 82 || event.key === 'r') &&
    //     (navigator.platform.match('Mac') ? event.metaKey : event.ctrlKey)
    //   ) {
    //     this.play()
    //     event.preventDefault()
    //   }
    },
    play () {
      const self = this
      const filePath = this.currentScript.filePath
      const url = '/control/start_playing?path=' + encodeURIComponent(filePath)
      self.$http
        .get(url)
        .then(function (response) {
          const respObj = response.data
          if (respObj.status !== 'success') {
            self.$notify({
              group: 'main',
              type: 'error',
              text: respObj.info
            })
          }
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
    },
    updateMode () {
      if (this.fileType === 'js') {
        this.language = 'javascript'
      } else if (this.fileType === 'ts') {
        this.language = 'typescript'
      } else if (this.fileType === 'lua') {
        this.language = 'lua'
      } else if (this.fileType === 'text') {
        this.language = 'text'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.span-btn-subtitle {
  font-size: 8px !important;
  margin-left: 5px;
}
.btn {
  height: 30px;
}
</style>
