const _console = console

const createLog = (util) => (...args) => {
  const fun = _console[util] || _console.log
  fun.apply(null, args)
}

const displayVersion = (title, version) =>
  createLog('log')(
    `%c ${title} %c ${version}`,
    'padding: 2px 1px; border-radius: 3px 0 0 3px; color: #fff; background: #606060; font-weight: bold;',
    'padding: 2px 1px; border-radius: 0 3px 3px 0; color: #fff; background: #42c02e; font-weight: bold;'
  )

const version = '0.9.5'
displayVersion('autotouch-dashboard', version)
