import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    host: null,
    deviceInfo: null,
    showLoading: false,
    showLeft: true,
    showLog: false,
    currentDir: null,
    currentFile: {
      filePath: null,
      content: null
    }
  },
  mutations: {
    setHost (state, host) {
      state.host = host
    },
    setDeviceInfo (state, deviceInfo) {
      state.deviceInfo = deviceInfo
    },
    setShowLoading (state, show) {
      state.showLoading = show
    },
    setShowLeft (state, show) {
      state.showLeft = show
    },
    setShowLog (state, show) {
      state.showLog = show
    },
    setCurrentDir (state, dir) {
      state.currentDir = dir
    },
    setCurrentFile (state, file) {
      state.currentFile = file
    }
  },
  getters: {
    getHost: state => {
      return state.host
    },
    getDeviceInfo: state => {
      return state.deviceInfo
    },
    getShowLoading: state => {
      return state.showLoading
    },
    getShowLeft: state => {
      return state.showLeft
    },
    getShowLog: state => {
      return state.showLog
    },
    getCurrentDir: state => {
      return state.currentDir
    },
    getCurrentFile: state => {
      return state.currentFile
    }
  },
  modules: {
  }
})
