<template>
  <div class="file-list-field">
    <div class="toolbar" style="padding-left:16px;padding-right:0;">
      <Breadcrumb />
      <div>
        <button class="btn btn-default btn-sm mr-2" v-on:click.stop.prevent="loadFiles()">
          <svg
            class="bi bi-arrow-repeat"
            width="2.0em"
            height="2.0em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M2.854 7.146a.5.5 0 00-.708 0l-2 2a.5.5 0 10.708.708L2.5 8.207l1.646 1.647a.5.5 0 00.708-.708l-2-2zm13-1a.5.5 0 00-.708 0L13.5 7.793l-1.646-1.647a.5.5 0 00-.708.708l2 2a.5.5 0 00.708 0l2-2a.5.5 0 000-.708z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M8 3a4.995 4.995 0 00-4.192 2.273.5.5 0 01-.837-.546A6 6 0 0114 8a.5.5 0 01-1.001 0 5 5 0 00-5-5zM2.5 7.5A.5.5 0 013 8a5 5 0 009.192 2.727.5.5 0 11.837.546A6 6 0 012 8a.5.5 0 01.501-.5z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <b-dropdown variant="button" size="sm" no-caret>
          <template slot="button-content">
            <svg
              class="bi bi-three-dots"
              width="2.0em"
              height="2.0em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
                clip-rule="evenodd"
              />
            </svg>
          </template>
          <b-dropdown-item v-on:click.stop.prevent="newFolder()">New Folder</b-dropdown-item>
          <b-dropdown-item v-on:click.stop.prevent="newFile()">New Script</b-dropdown-item>
          <b-dropdown-item v-b-modal.modal-upload-file>Upload File</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="file-list-wrapper">
      <table class="table table-striped" border="0" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th>Filename</th>
            <!-- <th>File Size</th> -->
            <th>Control</th>
            <th>Options</th>
          </tr>
        </thead>
      </table>
      <div class="file-list-content-wrapper">
        <table class="table table-striped" border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr v-for="file in files" v-bind:key="fileName(file)">
              <td>
                <img :src="`/icons/${file.iconName}`" />
                <router-link
                  v-if="isFolder(file.iconName) || isLuaPackage(file.filePath)"
                  :to="file.filePath"
                >{{ fileName(file) }}</router-link>
                <a v-else href="#" v-on:click.stop.prevent="clickOnFile(file)">{{ fileName(file) }}</a>
              </td>
              <!-- <td>{{ file.fileSize }}</td> -->
              <td>
                <template v-if="isScript(file.filePath)">
                  <button
                    class="btn btn-outline-secondary btn-sm"
                    v-if="!isRunning(file.filePath)"
                    v-on:click.stop.prevent="play(file.filePath)"
                  >Play</button>
                  <button
                    class="btn btn-secondary btn-sm"
                    v-else
                    v-on:click.stop.prevent="stop(file.filePath)"
                  >Stop</button>
                </template>
              </td>
              <td>
                <b-dropdown variant="button" size="sm" no-caret>
                  <template slot="button-content">
                    <svg
                      class="bi bi-gear"
                      width="1.6em"
                      height="1.6em"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 014.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 01-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 011.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 012.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 012.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 011.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 01-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 018.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 001.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 00.52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 00-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 00-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 00-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 00-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 00.52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 001.255-.52l.094-.319z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M8 5.754a2.246 2.246 0 100 4.492 2.246 2.246 0 000-4.492zM4.754 8a3.246 3.246 0 116.492 0 3.246 3.246 0 01-6.492 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </template>
                  <b-dropdown-item v-on:click.stop.prevent="deleteFile(file.filePath)">Delete</b-dropdown-item>
                  <b-dropdown-item v-on:click.stop.prevent="renameFile(file.filePath)">Rename</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal id="modal-upload-file" ref="modal-upload-file" title="Upload File" hide-footer>
      <form
        enctype="multipart/form-data"
        method="post"
        id="uploadForm"
        class="d-flex justify-content-between"
      >
        <input id="file" name="file" type="file" />
        <input
          type="submit"
          value="Upload"
          id="uploadSubmit"
          v-on:click.stop.prevent="uploadFile()"
        />
      </form>
    </b-modal>
  </div>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue'
const common = require('../common')

export default {
  name: 'FileList',
  components: {
    Breadcrumb
  },
  data () {
    return {
      files: [],
      currentPath: '/',
      runningScripts: [],
      refreshIntervalId: null
    }
  },
  created () {
    this.currentPath = this.$route.path
    this.$store.commit('setCurrentDir', this.currentPath)
    this.loadFiles()
    this.refreshIntervalId = setInterval(() => {
      this.getRunningScripts()
    }, 3000)
  },
  beforeRouteUpdate (to, from, next) {
    next()
    this.currentPath = this.$route.path
    this.$store.commit('setCurrentDir', this.currentPath)
    this.loadFiles()
  },
  destroyed () {
    clearInterval(this.refreshIntervalId)
  },
  methods: {
    clickOnFile (file) {
      this.$store.commit('setShowLog', false)
      const fileType = common.getFileType(file.filePath)
      if (
        fileType === 'js' ||
        fileType === 'lua' ||
        fileType === 'txt' ||
        fileType === 'img'
      ) {
        this.getFileContent(file.filePath)
      }
    },
    hasFiles () {
      return this.files && this.files.length > 0
    },
    loadFiles () {
      const self = this

      //   var files = require("../test-data.json");
      //   self.files = files.files;
      //   return;

      const filePath = this.currentPath
      const url = '/files?path=' + encodeURIComponent(filePath)
      self.$http
        .get(url)
        .then(function (response) {
          self.files = response.data.files
          self.$notify({
            group: 'main',
            type: 'success',
            text: 'Files reloaded!'
          })
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
    },
    getRunningScripts () {
      const self = this

      const url = '/runningScripts'
      self.$http
        .get(url)
        .then(function (response) {
          self.runningScripts = response.data
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    },
    fileName (file) {
      const fileName = common.getFilename(file.filePath)
      return fileName
    },
    fileNameLink (file) {
      const filePath = file.filePath
      const iconName = file.iconName

      let link
      if (common.isFolder(iconName)) {
        link = '/?path=' + encodeURIComponent(filePath)
      } else if (common.isImage(iconName)) {
        link = '/file/view?path=' + encodeURIComponent(filePath)
      } else {
        link = '/file/edit?path=' + encodeURIComponent(filePath)
      }
      return link
    },
    play (filePath) {
      const self = this
      const url = '/control/start_playing?path=' + encodeURIComponent(filePath)
      self.$http
        .get(url)
        .then(function (response) {
          const respObj = response.data
          if (respObj.status !== 'success') {
            self.$notify({
              group: 'main',
              type: 'error',
              text: respObj.info
            })
          }
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
    },
    stop (filePath) {
      const self = this
      const url = '/control/stop_playing?path=' + encodeURIComponent(filePath)
      self.$http
        .get(url)
        .then(function (response) {
          const respObj = response.data
          if (respObj.status !== 'success') {
            self.$notify({
              group: 'main',
              type: 'error',
              text: respObj.info
            })
          }
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
    },
    renameFile (oldFilePath) {
      const self = this

      const newFilename = prompt('Please input the new filename', '')
      if (newFilename) {
        const newFilePath = self.currentPath + '/' + newFilename
        askServerToRenameFile(oldFilePath, newFilePath)
      }

      function askServerToRenameFile (oldFilePath, newFilePath) {
        const url =
          '/file/rename?path=' +
          encodeURIComponent(oldFilePath) +
          '&newPath=' +
          encodeURIComponent(newFilePath)

        self.$http
          .get(url)
          .then(function (response) {
            const respObj = response.data
            if (respObj.status === 'success') {
              self.loadFiles()
            } else {
              self.$notify({
                group: 'main',
                type: 'error',
                text: respObj.info
              })
            }
          })
          .catch(function (error) {
            self.$notify({ group: 'main', type: 'error', text: error })
          })
      }
    },
    deleteFile (filePath) {
      const self = this
      if (confirm('Are you sure to delete the file?')) {
        const url = '/file/delete?path=' + encodeURIComponent(filePath)
        self.$http
          .get(url)
          .then(function (response) {
            const respObj = response.data
            if (respObj.status === 'success') {
              self.loadFiles()
            } else {
              self.$notify({
                group: 'main',
                type: 'error',
                text: respObj.info
              })
            }
          })
          .catch(function (error) {
            self.$notify({ group: 'main', type: 'error', text: error })
          })
      }
    },
    isFolder (iconName) {
      return common.isFolder(iconName)
    },
    isLuaPackage (filePath) {
      return common.isLuaPackage(filePath)
    },
    isScript (filePath) {
      return common.isScript(filePath)
    },
    isRunning (filePath) {
      return this.runningScripts.includes(filePath)
    },
    newFile () {
      const self = this

      let filename = prompt('Please input the filename', '')
      if (filename) {
        if (
          !filename.toLowerCase().endsWith('.txt') &&
          !filename.toLowerCase().endsWith('.lua') &&
          !filename.toLowerCase().endsWith('.js')
        ) {
          filename = filename + '.js'
        }
        const newFilePath = self.currentPath + '/' + filename
        askServerToNewFile(newFilePath)
      }

      function askServerToNewFile (filePath) {
        const url = '/file/new?path=' + encodeURIComponent(filePath)
        self.$http
          .get(url)
          .then(function (response) {
            const respObj = response.data
            if (respObj.status === 'success') {
              self.loadFiles()
            } else {
              self.$notify({
                group: 'main',
                type: 'error',
                text: respObj.info
              })
            }
          })
          .catch(function (error) {
            self.$notify({ group: 'main', type: 'error', text: error })
          })
      }
    },
    newFolder () {
      const self = this

      const filename = prompt('Please input the folder name', '')
      if (filename) {
        const newFilePath = self.currentPath + '/' + filename
        askServerToNewFile(newFilePath)
      }

      function askServerToNewFile (filePath) {
        const url = '/file/newFolder?path=' + encodeURIComponent(filePath)
        self.$http
          .get(url)
          .then(function (response) {
            const respObj = response.data
            if (respObj.status === 'success') {
              self.loadFiles()
            } else {
              self.$notify({
                group: 'main',
                type: 'error',
                text: respObj.info
              })
            }
          })
          .catch(function (error) {
            self.$notify({ group: 'main', type: 'error', text: error })
          })
      }
    },
    uploadFile () {
      const self = this
      const file = document.getElementById('file').files[0]
      if (file) {
        const filePath = this.currentPath + '/' + file.name
        const action = '/file/upload?path=' + encodeURIComponent(filePath)

        const formData = new FormData()
        formData.append('file', file)
        self.$http
          .post(action, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(function (response) {
            const respObj = response.data
            if (respObj.status === 'success') {
              self.loadFiles()
              self.$refs['modal-upload-file'].hide()
              self.$notify({
                group: 'main',
                type: 'success',
                text: 'Uploaded successfully!'
              })
            } else {
              self.$notify({
                group: 'main',
                type: 'error',
                text: respObj.info
              })
            }
          })
          .catch(function (error) {
            self.$notify({ group: 'main', type: 'error', text: error })
          })
      }
    },
    getFileContent (filePath) {
      const self = this

      self.$store.commit('setShowLoading', true)

      const url = '/file/content?path=' + encodeURIComponent(filePath)
      self.$http
        .get(url)
        .then(function (response) {
          const respObj = response.data
          if (respObj.status === 'success') {
            const content = respObj.content
            self.$store.commit('setCurrentFile', {
              filePath,
              content
            })
          } else {
            self.$notify({
              group: 'main',
              type: 'error',
              text: respObj.info
            })
          }
        })
        .catch(function (error) {
          self.$notify({ group: 'main', type: 'error', text: error })
        })
        .finally(function () {
          self.$store.commit('setShowLoading', false)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.file-list-field {
  width: 100%;

  .file-list-wrapper {
    position: absolute;
    top: 40px;
    left: 0;
    bottom: 0;
    width: 100%;

    border: 1px solid #eeeeee;

    .file-list-content-wrapper {
      position: absolute;
      top: 35px;
      bottom: 0;
      width: 100%;
      overflow-x: auto;
    }

    table {
      &:first-child {
        margin-bottom: 0;

        thead {
          tr {
            border: none;
            border-bottom: 1px solid #eeeeee;
            width: 100%;
            th {
              border: none;
              &:nth-child(2) {
                width: 100px;
                text-align: center;
              }
              &:last-child {
                width: 100px;
                text-align: right;
              }
            }
          }
        }
      }
      &:last-child {
        margin-top: 0;
        border-top: none;

        tbody {
          tr {
            td {
              &:first-child {
                img {
                  width: 20px;
                  height: 20px;
                  margin-right: 3px;
                }
              }
              &:nth-child(2) {
                width: 100px;
                text-align: center;
              }
              &:last-child {
                width: 100px;
                text-align: right;
              }
            }
          }
        }
      }

      th,
      td {
        padding: 0.5rem 1rem;
      }
    }
  }
}
</style>
